<template>
  <form-dialog
    v-model="dialog"
    :title="child.name"
    max-width="500px"
    v-on:submit="handleSubmit"
    :submit-text="btn_submit"
    :is-saving="is_saving"
    :submit-disabled="avatar.processing"
  >
    <template v-slot:content>
      <v-stepper
        alt-labels
        v-model="stepper"
        class="welcome-stepper child-stepper"
      >
        <v-stepper-header>
          <v-stepper-step step="1" :complete="stepper > 1">{{
            $t("Avatar")
          }}</v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step step="2" :complete="stepper > 2">{{
            $t("Presentation")
          }}</v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step step="3">{{ $t("Birth") }}</v-stepper-step>
        </v-stepper-header>
        <v-divider></v-divider>

        <v-stepper-content :key="1" :step="1">
          <v-card-subtitle
            class="pa-0"
            v-html="$t('AvatarDesc', { name: child_profile.name })"
          ></v-card-subtitle>
          <div class="text-center pt-4">
            <v-avatar height="150" width="150" color="grey lighten-4">
              <img
                :src="getAvatar(child_profile.avatar)"
                :alt="child_profile.name"
                :title="child_profile.name"
              />
              <v-overlay absolute :value="avatar.processing">
                <v-progress-circular
                  indeterminate
                  size="64"
                ></v-progress-circular>
              </v-overlay>
            </v-avatar>

            <input
              type="file"
              style="display: none"
              ref="image"
              accept="image/*"
              v-on:change="onAvatarPicked"
            />

            <div class="pt-3">
              <v-btn
                :disabled="is_saving || avatar.processing"
                small
                outlined
                class="text-none"
                @click="pickAvatar"
              >
                <v-icon small left>$vuetify.icons.upload</v-icon>
                {{ $t("SelectAvatar") }}</v-btn
              >
              <v-btn
                :disabled="is_saving || avatar.processing"
                small
                v-if="avatar.file"
                outlined
                color="red"
                class="text-none ml-2"
                @click="emptyAvatar"
              >
                {{ $t("RemoveAvatar") }}</v-btn
              >
            </div>

            <div class="caption pt-4 text-center" v-if="avatar.file">
              {{ $t("ClickNextToSave") }}
            </div>
          </div>
        </v-stepper-content>

        <v-stepper-content :key="2" :step="2">
          <v-card-subtitle
            class="pa-0"
            v-html="$t('PresentationDesc', { name: child_profile.name })"
          ></v-card-subtitle>
          <div class="text-center pt-5">
            <v-textarea
              :disabled="is_saving"
              :label="$t('Presentation')"
              counter
              maxlength="100"
              outlined
              no-resize
              rows="3"
              v-model="child_profile.description"
            ></v-textarea>
          </div>
        </v-stepper-content>

        <v-stepper-content :key="3" :step="3">
          <v-card-subtitle
            class="pa-0"
            v-html="$t('BirthDesc', { name: child_profile.name })"
          ></v-card-subtitle>
          <div class="pt-4 text-center">
            <v-form ref="sizeForm" v-model="size_form" lazy-validation>
              <v-text-field
                :label="$t('Length')"
                v-model="size.length"
                required
                :loading="is_saving"
                :disabled="is_saving"
                outlined
                :rules="[
                  (v) => !!v || $t('LengthRequired'),
                  (v) => /^[0-9.,]+$/.test(v) || $t('LengthNotValid'),
                ]"
                :suffix="diary.length_format"
              ></v-text-field>

              <v-text-field
                :label="$t('Weight')"
                v-model="size.weight"
                required
                class="mt-1"
                :disabled="is_saving"
                outlined
                :rules="[
                  (v) => !!v || $t('WeightRequired'),
                  (v) => /^[0-9.,]+$/.test(v) || $t('WeightNotValid'),
                ]"
                :suffix="diary.weight_format"
              ></v-text-field>

              <v-text-field
                :label="$t('Headsize')"
                v-model="size.headsize"
                required
                class="mt-1"
                :disabled="is_saving"
                outlined
                :rules="[
                  (v) => !!v || $t('HeadsizeRequired'),
                  (v) => /^[0-9.,]+$/.test(v) || $t('HeadsizeNotValid'),
                ]"
                :suffix="diary.length_format"
              ></v-text-field>
            </v-form>
          </div>
        </v-stepper-content>
      </v-stepper>
    </template>
  </form-dialog>
</template>

<i18n>
    {
    "en": {
      "Avatar": "Picture",
      "Presentation": "Presentation",
      "Birth": "Birth",
      "AvatarDesc": "{name} was added. Now you have the opportunity to present {name} a little more. We begin with the profile picture. You can exit this guide at any time.",
      "PresentationDesc": "Now you have the opportunity to write a shorter presentation about {name}.",
      "BirthDesc": "Add the birth weight, height and head circumference that {name} had at birth.",
      "SelectAvatar": "Select picture",
      "SuccessUpdate": "Information saved!",
      "AvatarUploaded": "Picture saved!",
      "Length": "Length",
      "Weight": "Weight",
      "Headsize": "Head circumference",
      "HeadsizeRequired": "Head circumference is missing!",
      "LengthRequired": "Length is required!",
      "WeightRequired" : "Weight is required!",
      "RemoveAvatar": "Remove",
      "ClickNextToSave": "Click next to save",
      "LengthNotValid": "Length not valid. Use 0-9 , .",
      "WeightNotValid": "Weight not valid. Use 0-9 , .",
      "HeadsizeNotValid": "Head circumference not valid. Use 0-9 , ."
    },
    "sv": {
      "Avatar": "Bild",
      "Presentation": "Presentation",
      "Birth": "Födelse",
      "AvatarDesc": "{name} lades till. Nu har du möjlighet att presentera {name} lite mer. Vi börjar med profilbilden. Du kan avsluta den här guiden när som helst.",
      "PresentationDesc": "Här har du möjlighet att skriva en kortare presentation om {name}.",
      "BirthDesc": "Registrera födelse-vikten, längden och huvudomfånget.",
      "SelectAvatar": "Vilj bild",
      "SuccessUpdate": "Informationen sparades",
      "AvatarUploaded": "Bilden sparades!",
      "LengthRequired": "Längd saknas!",
      "WeightRequired" : "Vikt saknas!",
      "Headsize": "Huvudomfång",
      "HeadsizeRequired": "Huvudomfång saknas!",
      "Length": "Längd",
      "Weight": "Vikt",
      "RemoveAvatar": "Ta bort",
      "ClickNextToSave": "Klicka nästa för att spara",
      "LengthNotValid": "Längd är inte korrekt. Använd 0-9 , .",
      "WeightNotValid": "Vikt är inte korrekt. Använd 0-9 , .",
      "HeadsizeNotValid": "Huvudomfång är inte korrekt. Använd 0-9 , ."
    }
    }
</i18n>

<script>
import { mapActions, mapState } from "vuex";
import Compressor from "compressorjs";
import { childrenService } from "../../../_services";

export default {
  name: "welcome_child_dialog",
  computed: {
    ...mapState({
      user: (state) => state.account.user,
      diary: (state) => state.diary.profile,
      is_admin: (state) => state.diary.is_admin,

      child: (state) => state.diary.childprofile.child,
    }),
    btn_submit: function () {
      if (this.stepper === 3) {
        return this.$t("Button.Done");
      } else return this.$t("Button.Next");
    },
  },
  data: () => ({
    dialog: true,
    stepper: 1,

    avatar: {
      file: null,
      processing: false,
    },

    size: {
      date: "",
      length: "",
      weight: "",
      headsize: "",
    },
    size_form: false,

    is_saving: false,

    child_profile: {},
  }),
  created: function () {
    this.child_profile = { ...this.child };
    this.size.date = this.child.birth;
  },
  methods: {
    ...mapActions({
      uploadChildAvatar: "diary/childprofile/uploadAvatar",
      updateDescription: "diary/childprofile/updateDescription",
    }),
    doneStep() {
      this.stepper += 1;
    },
    handleSubmit() {
      if (this.stepper == 1) {
        if (this.avatar.file != null) {
          this.saveAvatar();
        } else {
          this.stepper += 1;
        }
      } else if (this.stepper == 2) {
        this.saveDescription();
      } else {
        if (
          this.size.weight == "" &&
          this.size.length == "" &&
          this.size.headsize == ""
        ) {
          this.handleClose();
        } else {
          this.saveSize();
        }
      }
    },
    handleClose() {
      this.$emit("closed");
      this.dialog = false;
    },
    pickAvatar() {
      this.$refs.image.click();
    },
    onAvatarPicked(e) {
      var sender = this;

      const file = e.target.files[0];

      if (!file) {
        return;
      }
      sender.avatar.processing = true;
      sender.avatar.error = null;

      new Compressor(file, {
        quality: 0.8,
        minWidth: 100,
        minHeight: 100,
        maxHeight: 800,
        maxWidth: 800,
        success(result) {
          var urlCreator = window.URL || window.webkitURL;
          var img = urlCreator.createObjectURL(result);

          sender.child_profile.avatar = img;
          sender.avatar.file = result; // this is an image file that can be sent to server...
          sender.avatar.processing = false;
        },
        error(err) {
          sender.avatar.error = err.message;
          sender.avatar.processing = false;
        },
      });
    },
    saveAvatar() {
      var self = this;
      self.is_saving = true;

      self
        .uploadChildAvatar(self.avatar.file)
        .then(function () {
          self.is_saving = false;
          self.avatar.file = null;
          self.stepper++;
          self.$successNoty(self.$t("AvatarUploaded"));
        })
        .catch(function (error) {
          self.$ajaxErrorNoty(error);
          self.is_saving = false;
        });
    },
    emptyAvatar() {
      this.child_profile.avatar = "";
      this.avatar.file = null; // this is an image file that can be sent to server...
      this.avatar.processing = false;
    },
    getAvatar(image) {
      if (typeof image === "object")
        return this.$imagehelpers.childAvatar(image);

      if (image.indexOf("blob") > -1) return image;

      return this.$imagehelpers.childAvatar(image);
    },
    saveDescription() {
      var self = this;
      self.is_saving = true;

      self
        .updateDescription(self.child_profile.description)
        .then(function () {
          self.is_saving = false;
          self.stepper++;
        })
        .catch(function (error) {
          self.is_saving = false;
          self.$ajaxErrorNoty(error);
        });
    },
    saveSize() {
      var self = this;

      if (self.$refs.sizeForm.validate()) {
        self.is_saving = true;

        childrenService
          .createSize(self.child.id, self.size)
          .then(function () {
            self.$store.commit("diary/childprofile/welcomeStatus", false, {
              root: true,
            });
            self.$successNoty(self.$t("SuccessUpdate"));
            self.is_saving = false;
            self.handleClose();
          })
          .catch(function (error) {
            self.$ajaxErrorNoty(error);
            self.is_saving = false;
          });
      }
    },
  },
};
</script>

<style scoped>
.headline {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: calc(100% - 100px);
}

.child-stepper {
  box-shadow: none !important;
}
</style>