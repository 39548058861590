import { render, staticRenderFns } from "./WelcomeNewChildDialog.vue?vue&type=template&id=7a3b71c6&scoped=true&"
import script from "./WelcomeNewChildDialog.vue?vue&type=script&lang=js&"
export * from "./WelcomeNewChildDialog.vue?vue&type=script&lang=js&"
import style0 from "./WelcomeNewChildDialog.vue?vue&type=style&index=0&id=7a3b71c6&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7a3b71c6",
  null
  
)

/* custom blocks */
import block0 from "./WelcomeNewChildDialog.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VStepper } from 'vuetify/lib/components/VStepper';
import { VStepperContent } from 'vuetify/lib/components/VStepper';
import { VStepperHeader } from 'vuetify/lib/components/VStepper';
import { VStepperStep } from 'vuetify/lib/components/VStepper';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VAvatar,VBtn,VCardSubtitle,VDivider,VForm,VIcon,VOverlay,VProgressCircular,VStepper,VStepperContent,VStepperHeader,VStepperStep,VTextField,VTextarea})
